import { cn } from "~/lib/utils"
import { buttonVariants } from "~/components/ui/button"
import { Icons } from "~/components/icons"
import { Meta, Title } from "@solidjs/meta"
import { Label } from "../../components/ui/label"
import { Input } from "../../components/ui/input"
import { action, json, redirect, useSubmission } from "@solidjs/router"
import { ClientResponseError } from "pocketbase"
import { getNewPocketBase } from "../../lib/pb"

const signupAction = action(async (formData: FormData) => {
  "use server"

  // #region 1. 检查表单数据
  const email = formData.get("email") as string | null
  if (!email) {
    return json(
      { errors: { email: "请输入邮箱", password: null } },
      { status: 400 },
    )
  }

  const password = formData.get("password") as string | null
  if (!password) {
    return json(
      { errors: { email: null, password: "请输入密码" } },
      { status: 400 },
    )
  }

  const passwordConfirm = String(formData.get("passwordConfirm"))
  if (password !== passwordConfirm) {
    return json(
      { errors: { email: null, password: "两次输入的密码不一致" } },
      { status: 400 },
    )
  }
  // #endregion

  // 2. 去注册，使用 pocketbase 的 API
  try {
    const pb = getNewPocketBase()
    await pb.collection("users").create({
      username: email!.replace("@", "_"),
      email,
      password,
      passwordConfirm,
    })
    // 3. 更新 cookie 跳转到 dashboard
    await pb.collection("users").authWithPassword(email!, password!)
    return redirect("/dashboard", {
      headers: {
        "Set-Cookie": pb.authStore.exportToCookie(),
      },
    })
  } catch (error) {
    console.error(error)
    if (error instanceof ClientResponseError) {
      if (error.response.data?.email?.code === "validation_invalid_email") {
        return json(
          {
            errors: {
              email: error.response.data?.email?.message,
              password: null,
            },
          },
          { status: 400 },
        )
      }
      return json(
        {
          errors: {
            email: null,
            password: null,
            // unknown: "注册失败，请稍后再试",
          },
        },
        { status: 400 },
      )
    }
  }
})

export default function SignupPage() {
  const submission = useSubmission(signupAction)

  return (
    <div class="container grid h-screen w-screen flex-col items-center justify-center lg:max-w-none lg:grid-cols-2 lg:px-0">
      <Title>注册账号</Title>
      <Meta name="description" content="注册账号" />
      <a
        href="/auth/login"
        class={cn(
          buttonVariants({ variant: "ghost" }),
          "absolute right-4 top-4 md:right-8 md:top-8",
        )}
      >
        Login
      </a>
      <div class="hidden h-full bg-muted lg:block" />
      <div class="lg:p-8">
        <div class="mx-auto flex w-full flex-col justify-center space-y-6 sm:w-[350px]">
          <div class="flex flex-col space-y-2 text-center">
            <Icons.logo class="mx-auto h-6 w-6" />
            <h1 class="text-2xl font-semibold tracking-tight">注册</h1>
            <p class="text-sm text-muted-foreground">
              {/* Enter your email below to create your account */}
            </p>
          </div>
          <div class="grid gap-6">
            <style
              innerHTML={`
                input:user-invalid{ border:2px solid red; }
            `}
            ></style>
            <form action={signupAction} method="post">
              <div class="grid gap-2">
                <div class="grid gap-1">
                  <Label for="email">邮箱：</Label>
                  <Input
                    id="email"
                    name="email"
                    placeholder="name@example.com"
                    type="email"
                    required
                    minlength="3"
                    disabled={submission.pending}
                  />
                  {submission.result?.errors?.email && (
                    <p class="px-1 text-xs text-red-600">
                      {submission.result?.errors?.email}
                    </p>
                  )}
                </div>
                <div class="grid gap-1">
                  <Label for="password">密码：（最少6个字符）</Label>
                  <Input
                    id="password"
                    name="password"
                    required
                    minlength="6"
                    placeholder=""
                    type="password"
                    disabled={submission.pending}
                  />
                  {submission.result?.errors?.password && (
                    <p class="px-1 text-xs text-red-600">
                      {submission.result?.errors?.password}
                    </p>
                  )}
                </div>
                <div class="grid gap-1">
                  <Label for="passwordConfirm">
                    再次输入密码：（最少6个字符）
                  </Label>
                  <Input
                    id="passwordConfirm"
                    name="passwordConfirm"
                    required
                    minlength="6"
                    placeholder="请再输入一次密码"
                    type="password"
                    disabled={submission.pending}
                  />
                  {submission.result?.errors?.password && (
                    <p class="px-1 text-xs text-red-600">
                      {submission.result?.errors?.password}
                    </p>
                  )}
                </div>
                <button
                  class={cn(buttonVariants())}
                  disabled={submission.pending}
                >
                  {submission.pending && (
                    <Icons.spinner class="mr-2 h-4 w-4 animate-spin" />
                  )}
                  注册
                </button>
              </div>
            </form>
            {/* <div class="relative">
              <div class="absolute inset-0 flex items-center">
                <span class="w-full border-t" />
              </div>
              <div class="relative flex justify-center text-xs uppercase">
                <span class="bg-background px-2 text-muted-foreground">
                  Or continue with
                </span>
              </div>
            </div> */}
            {/* <button
              type="button"
              class={cn(buttonVariants({ variant: "outline" }))}
              onClick={() => {
                setIsGitHubLoading(true)
                signIn("github")
              }}
              disabled={isLoading() || isGitHubLoading()}
            >
              {isGitHubLoading() ? (
                <Icons.spinner class="mr-2 h-4 w-4 animate-spin" />
              ) : (
                <Icons.gitHub class="mr-2 h-4 w-4" />
              )}{" "}
              Github
            </button> */}
          </div>
          {/* <p class="px-8 text-center text-sm text-muted-foreground">
            By clicking continue, you agree to our{" "}
            <a
              href="/terms"
              class="hover:text-brand underline underline-offset-4"
            >
              Terms of Service
            </a>{" "}
            and{" "}
            <a
              href="/privacy"
              class="hover:text-brand underline underline-offset-4"
            >
              Privacy Policy
            </a>
            .
          </p> */}
        </div>
      </div>
    </div>
  )
}
